import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import customer from "../../assets/img/mert-haciosmanoglu.png"
import liva from "../../assets/img/liva-site-preview.png"
import detail from "../../assets/img/liva-convert-detail.png"
import detail1 from "../../assets/img/liva-convert-detail1.png"
import detail2 from "../../assets/img/liva-convert-detail3.png"
import detail3 from "../../assets/img/liva-convert-detail4.png"
import detail4 from "../../assets/img/liva-convert-detail5.png"
import detail5 from "../../assets/img/liva-convert-detail6.png"
import detail6 from "../../assets/img/liva-convert-detail7.png"
import OtherCaseStudy from "../../components/other-case"

import simay from "../../assets/img/teams/08.jpg"
import serife from "../../assets/img/teams/20.jpg"
import edalabasmaz from "../../assets/img/teams/29.jpg"
import eelbeyglu from "../../assets/img/teams/11.jpg"
import baris from "../../assets/img/teams/01.jpg"

import refDesign from "../../assets/img/ref-design.png"
import squad from "../../assets/img/squad.png"
import video from "../../assets/img/liva-pastacilik-basari-video.mp4"

const ReferancesDetail = () => (
  <Layout>
    <SEO title="Liva Pastacılık Success Story" 
    description="Liva Pastry, which has been serving in Ankara with its 8 branches since 1993, has achieved an increasing success rate over the years. Check it out."

    />
    <div className="referances-detail py60 container">
      <div className="col-md-6 detail-left">
        <b>SUCCESS STORIES</b>
        <h1>Liva Pastacılık</h1>
        <p>
          Liva Pastry, which has been serving in Ankara with its 8 branches
          since 1993, has achieved an increasing success rate over the years. In
          2016, the company that wanted to make digital breakthrough cooperated
          in the fields of SEO, SEM, digital advertisement management, web page
          design and social media management.
        </p>
        <p>
          The monthly order reached by Liva Pastry now is more than 3000 in
          Ankara province, thanks to the digital operation launched from the
          zero point.
        </p>
        <h2>Problems</h2>
        <ul>
          <li>
            &bull; Conversion rate is 1% compared to the traffic acquired,
          </li>
          <li>
            &bull; The monthly turnover increase is not at the desired level,
          </li>
          <li>&bull; 20% of repeated orders,</li>
          <li>
            &bull; Target keywords are placed in the 2nd page of search engines.
          </li>
        </ul>

        <h2>Solution</h2>
        <p>
          First of all, we found out the reasons for the low conversion rate
          below the industry average through Google Analytics. We designed a new
          website using Shopify infrastructure, which is mostly for the solution
          of problems related to website design and product variety. During this
          period, we also increased the product variety and resolved the
          conversion rate problem. By creating strategies for customer loyalty
          and organic traffic acquisition, we produced solutions as described
          below.
        </p>
      </div>
      <div className="col-md-6 referances-boxs">
        <div className="boxs">
          <div className="box">
            <span>%4.6</span>
            <p>E-Commerce conversion rate</p>
          </div>
          <div className="box">
            <span>+4500</span>
            <p>More than monthly orders</p>
          </div>
          <div className="box">
            <span>+60k</span>
            <p>Organic traffic acquired monthly.</p>
          </div>
          <div className="box">
            <span>+%61</span>
            <p>Monthly order Recurring customer</p>
          </div>
        </div>
        <div className="provided">
          <h3>Services Provided</h3>
          <div className="provided-box">
            <div className="box">
              <Link to="/email-marketing/"></Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 86 86"
              >
                <g
                  id="Icons-_-Communication-_-Mail-heart"
                  data-name="Icons-/-Communication-/-Mail-heart"
                  transform="translate(0.169 -0.307)"
                >
                  <rect
                    id="bound"
                    width="86"
                    height="86"
                    transform="translate(-0.169 0.307)"
                    fill="none"
                  />
                  <path
                    id="Combined-Shape"
                    d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z"
                    transform="translate(13.236 5.138)"
                    fill="#111"
                    fill-rule="evenodd"
                    opacity="0.3"
                  />
                  <path
                    id="Combined-Shape-2"
                    data-name="Combined-Shape"
                    d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z"
                    transform="translate(8.097 16.643)"
                    fill="#111"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              Email Marketing
            </div>
            <div className="box">
              <Link to="/ui-ux-design/"></Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 76 76"
              >
                <g
                  id="Icons-_-Design-_-Sketch"
                  data-name="Icons-/-Design-/-Sketch"
                  transform="translate(0.015 -0.307)"
                >
                  <rect
                    id="bound"
                    width="76"
                    height="76"
                    transform="translate(-0.015 0.307)"
                    fill="none"
                  />
                  <path
                    id="Path-48"
                    d="M13.728,3H58.276L71,18.91H1Z"
                    transform="translate(2.182 6.546)"
                    fill-rule="evenodd"
                    opacity="0.3"
                  />
                  <path
                    id="Path-48-Copy"
                    d="M71,8,36,46.184,1,8Z"
                    transform="translate(2.182 17.456)"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              UI&UX Design
            </div>
            <div className="box">
              <Link to="/search-engine-optimization/"></Link>
              <svg
                style={{ marginLeft: -7 }}
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 79 80"
              >
                <g
                  id="Icons-_-Shopping-_-Chart-line_1"
                  data-name="Icons-/-Shopping-/-Chart-line#1"
                  transform="translate(-0.462 0.373)"
                >
                  <rect
                    id="bound"
                    width="79"
                    height="80"
                    transform="translate(0.462 -0.373)"
                    fill="none"
                  />
                  <path
                    id="Path-95"
                    d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z"
                    transform="translate(6.895 7.094)"
                    fill="#000000"
                  />
                  <path
                    id="Path-97"
                    d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z"
                    transform="translate(16.088 15.138)"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
              SEO
            </div>
            <div className="box">
              <Link to="/digital-ads/"></Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 76 76"
              >
                <g
                  id="Icons-_-Shopping-_-Cart_1"
                  data-name="Icons-/-Shopping-/-Cart#1"
                  transform="translate(0.452 0.373)"
                >
                  <rect
                    id="bound"
                    width="76"
                    height="76"
                    transform="translate(-0.452 -0.373)"
                    fill="none"
                  />
                  <path
                    id="Combined-Shape"
                    d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z"
                    transform="translate(4.899 6.203)"
                    fill-rule="evenodd"
                    opacity="0.3"
                  />
                  <path
                    id="Combined-Shape-2"
                    data-name="Combined-Shape"
                    d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z"
                    transform="translate(10.804 38.894)"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              Google Ads
            </div>
          </div>
        </div>
        <div className="project-details">
          <h3>Impression</h3>
          <div className="project-details_list">
            <ul>
              <li>Brand Manager:</li>
              <li>Digital Marketing Specialist:</li>
              <li>Google Ads:</li>
              <li>SEO:</li>
              <li>Website Development:</li>
            </ul>
            <ul>
              <li>
                <img src={simay} />
                Simay Akkurt
              </li>
              <li>
                <img src={edalabasmaz} />
                Emel Dalabasmaz
              </li>
              <li>
                <img src={serife} alt="Şerife Aytekin" />
                Şerife Aytekin
              </li>
              <li>
                <img src={eelbeyglu} alt="Alaattin Emre Elbeyoğlu" />
                Emre Elbeyoğlu
              </li>
              <li>
                <img src={baris} />
                Barış Aslan
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="testimonial-customer" id="one">
        <p>
          “Thank you for constantly trying to enlighten us on every issue as
          well as enabling us to get more sales by increasing the visibility of
          our brand in digital”
        </p>
        <div className="author">
          <div>
            <img src={customer}></img>
          </div>
          <div className="author-name">
            Mert Hacıosmanoğlu
            <br />
            <span>Member of the Board</span>
          </div>
        </div>
      </div>

      <div className="referances-video">
        <div className="col-md-6 referances-video_img">
          {/* <img src={video} className="fake-youtube" alt="pink youtube video thumpnail" /> */}
          <video className="fake-youtube" controls>
            <source src={video} type="video/mp4" />
          </video>
          <img alt="alt text" className="design-squad" src={squad} />
          <img alt="alt text" className="design-ref" src={refDesign} />
        </div>
        <div className="col-md-6 referances-video_content">
          <h2>Liva Pastacılık</h2>
          <b>Mert Hacıosmanoğlu, Member of the Board</b>
          <div className="referances-video_content-list">
            <ul className="headers">
              <li>%4.6: </li>
              <li>+4500: </li>
              <li>+60k: </li>
              <li>+%61: </li>
            </ul>
            <ul>
              <li>E-commerce Turnover Conversion Rate</li>
              <li>Monthly Order Number</li>
              <li>Organic Traffic Acquired Monthly</li>
              <li>Monthly Recurring Order</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="site-preview">
        <div className="mockup">
          <img src={liva} />
        </div>
      </div>

      <div className="customer-content">
        <h2>
          Accurate design, high turnover,
          <br />
          conversion rate.
        </h2>
        <div className="row-img">
          <img src={detail} />
          <img src={detail1} />
        </div>
        <p>
          In addition to digital marketing operations, the interface and
          usability of the e-commerce website are also very important. Errors
          due to negative user experience and browser incompatibilities prevent
          maximum conversion.
        </p>
        <p>
          The Shopify e-commerce infrastructure, which was continuously updated
          and developed by more than 2000 employees, was preferred for the Liva
          Pastry e-commerce website created from scratch.
        </p>
        <p>
          {" "}
          As a result of the new Liva Pastry interface that we designed with the
          Shopify infrastructure, which has developments and integrations that
          cannot be made by companies and is also used by 500,000 e-commerce
          sites, the conversion rate has increased by 60% and there has been an
          increase in traffic and turnover.
        </p>
        <h2>
          425% organic
          <br />
          traffic growth in 2 years
        </h2>
        <p>
          With content marketing and link building, we achieved the first 3
          ranks and first page targets for the keywords targeted in 2 years.
          Together with Liva Blog, we are providing more than 60,000 traffic
          from Google organically.
        </p>
        <div className="row-img">
          <img src={detail2} />
          <img style={{ borderRadius: 10 }} src={detail3} />
        </div>
        <p style={{ marginBottom: 0 }}>
          As seen in the graphic below, we are positioned in the top 3 in target
          words such as "birthday cake", "liva", "order cake" in the sector. In
          addition, we receive traffic from search engines in a total of 11,312
          words.
        </p>
        <img className="full-shadow" src={detail4} />
        <h2>
          Maximum turnover conversion rate
         with low cost per click.
        </h2>
        <img style={{ width: "70%", margin: "0 auto" }} src={detail5} />
        <p>
          With an effective ad management, we have carried out works to ensure
          that the brand gets high efficiency from low cost-per-click
          advertisements.
        </p>
        <h2>
          Loyal customers.
          <br />
          Regular increasing turnover.
        </h2>
        <img style={{ width: "50%", margin: "0 auto" }} src={detail6} />
        <p>
          User loyalty and continuity of success are crucial for solid growth.
          After establishing the first contact with the customer during
          shopping, we make our communication continuous with methods such as
          mailing, social media interaction and notification. With these
          reminders and communication strategies, we have increased the rate of
          re-ordering customers to 61%.
        </p>
      </div>
    </div>

    <OtherCaseStudy />

    <Checkup />
  </Layout>
)

export default ReferancesDetail
